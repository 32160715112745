import pkg from '../../package.json';
import i18n from '@/i18n/i18n';
import { SETTINGS_PANEL_ID, INSTANT_PAST_PANEL_ID } from '@/lib/variables/panels';
import { TYPE_VIS, STATUS_VIS, INITIAL_BOUNDS_KPIS } from '@/components/operations/map/sections/map/subcomponents/mapVariables';
import {
	AIS_VESSEL_GROUP_ID,
	STOP_PREVISION_GROUP_ID,
	STOP_ACCEPTED_LAYER_ID,
	STOP_PLANNED_LAYER_ID,
	STOP_AUTHORIZED_LAYER_ID,
	STOP_CONFIRMED_LAYER_ID,
	STOP_INITIATED_LAYER_ID,
	FILTER_AIS_VISIT_LAYER_ID,
	FILTER_SERVICE_LAYER_ID,
	FILTER_OTHER_VESSEL_LAYER_ID,
	CEP_ZONES_ID
} from '@/components/operations/map/constants/layers';

import { getStatusStops } from '@/mixins/VisitsMixin';

import {
	FEC_TODAY,
	FEC_PAST_INI,
	FEC_PAST_END,
	FEC_FUTURE_END,
	FEC_REQPL_INI
} from '@/components/operations/map/sections/infoperiods/InitialInstantDates';

export default {
	global: {
		appVersion: pkg.version,
		appDefaultEntryPoint: 'home',
		baseUrl: process.env.VUE_APP_BASE_SERVER_URL,
		webSocketsEnabled: true,
		webSocketsUrl: process.env.VUE_APP_PUI9_WEBSOCKETS_URL,
		appHeaderMapHeightInPx: 52,
		langContainerActionHeader: true,
		containerActionHeader: true,
		appMenuBottomBar: true,
		singleItemAutoselectOnRequired: true
	},
	login: {
		forgetPassword: true,
		afterLoginFunction: null,
		afterLogoutFunction: null
	},
	menu: {
		homeBtn: true,
		searchBtn: false,
		infoBtn: false,
		notificationsBtn: false,
		helpBtn: false,
		keepExpanded: true,
		items: null,
		showLeftBar: true
	},
	grid: {
		showRowActions: false
	},
	operations: {
		etcSafetyMargin: null,
		configPortCompany: null,
		shipSelectedId: 0, //Default
		portAuthorityId: 0, //Default
		portlocode: 0
	},
	berthplanner: {
		defaultStatus: getStatusStops(),
		defaultQuayTTIA: {
			id: 51,
			portid: 1,
			berthcode: 'MUESTE',
			seaside: 'L',
			name: 'MUELLE ESTE',
			azimuth: 177.8175795999,
			initbolcode: 1,
			endbolcode: 26,
			invertbollards: 1
		},
		defaultQuayAPMT: {
			id: 81119,
			portid: 1,
			berthcode: 'JCI',
			seaside: 'L',
			name: 'JUAN CARLOS I',
			groupIds: [8, 11, 19]
		},
		defaultQuayAPMTLC: {
			id: 7576,
			portid: 3,
			berthcode: 'TEC II',
			seaside: 'L',
			name: 'TEC II',
			initbolcode: 1,
			endbolcode: 52,
			groupIds: [75, 76]
		},
		defaultQuayHPLCT: {
			id: 737477,
			portid: 3,
			berthcode: 'TEC I',
			seaside: 'L',
			name: 'TEC I',
			initbolcode: 1,
			endbolcode: 123,
			groupIds: [73, 74, 77]
		},
		defaultQuayOPLP: {
			id: 9099,
			portid: 4,
			berthcode: 'OPCSA',
			seaside: 'R',
			name: 'CCOLÓN y LCASTILLO',
			initbolcode: 1,
			endbolcode: 84,
			groupIds: [90, 99]
		},
		defaultQuayGESLP: {
			id: 100,
			portid: 4,
			berthcode: '0104',
			seaside: 'L',
			name: 'MUELLE GRAN CANARIA (13-20)',
			initbolcode: 13,
			endbolcode: 20,
			groupIds: [100]
		},
		defaultQuayGESAR: {
			id: 9596,
			portid: 6,
			berthcode: 'TRANAC',
			name: 'TRANSBORDO NACIENTE (9-26)',
			seaside: 'L',
			initbolcode: 9,
			endbolcode: 26,
			groupIds: [95, 96]
		},
		defaultQuayMAFV: {
			id: 98,
			portid: 5,
			berthcode: '0003',
			name: 'COMERCIAL III (16-24)',
			seaside: 'L',
			initbolcode: 16,
			endbolcode: 32,
			groupIds: [98]
		},
		defaultQuayMOAR: {
			id: 97,
			portid: 6,
			berthcode: '0020',
			name: 'OBLICUO',
			seaside: 'L',
			initbolcode: 2,
			endbolcode: 8,
			groupIds: [97]
		},
		defaultQuayROBO: {
			id: 101,
			portid: 5,
			berthcode: '0003',
			name: 'COMERCIAL III (25-32)',
			seaside: 'L',
			initbolcode: 16,
			endbolcode: 32,
			groupIds: [101]
		},
		defaultQuayBOLP: {
			id: 939192,
			portid: 4,
			berthcode: 'BOLP',
			seaside: 'L',
			name: 'G.CANARIA, PINO y P.NACIENTE',
			groupIds: [93, 91, 92]
		},
		rqColor: '#C8D8E4',
		acColor: '#B3D5F2',
		auColor: '#5A86BF',
		coColor: '#C3D9B8',
		waColor: '#F5D8C5',
		inColor: '#F5A494',
		fiColor: '#F5D89F',
		unknownColor: '#F5F3ED',
		cargooperColor: '#EDCABE',
		compcargooperColor: '#E6BB97',
		berthblockColor: '#B82A48'
	},
	masters: {
		userPortsId: []
	},

	mapconfig: {
		userQuaysId: [],
		berths: [],
		bollards: {},
		mapPortsExtents: null,
		mapAutorityExtent: null,
		stopsStatusValues: null,
		stopsTypeValues: null,
		stopsOperationsValues: {
			vesselvisit: {
				color: '#f8c5be',
				coloroutline: '#e8311a',
				text: i18n.t('legend.operationsVis.vesselvisit').toUpperCase(),
				shape: 'point'
			},
			isPilot: {
				color: '#dcd0ed',
				coloroutline: '#7b45b8',
				text: i18n.t('legend.operationsVis.pilot').toUpperCase(),
				shape: 'point'
			},
			isTug: {
				color: '#ffd0ec',
				coloroutline: '#db49a0',
				text: i18n.t('legend.operationsVis.tug').toUpperCase(),
				shape: 'point'
			},
			otherVesselServices: {
				color: '#bcf0ce',
				coloroutline: '#24a54e',
				text: i18n.t('legend.operationsVis.othervesselsservices').toUpperCase(),
				shape: 'point'
			},
			otherVessel: {
				color: '#bacff8',
				coloroutline: '#2535c8',
				text: i18n.t('legend.operationsVis.othervessels').toUpperCase(),
				shape: 'point'
			}
		},
		portAutorityHaveStatusPl: false,
		defaultBackgroundColor: null, // Get from pui_variable
		defaultStrokeColor: null // Get from pui_variable
	},

	// Nuevo operations
	mappanelais: {
		map: null,
		mapAisIsLoaded: false,
		mapStyleVisualization: TYPE_VIS,
		mapPortVisualization: 0,
		mapFitPortExtent: null,

		mapPortLocodeSelected: null,

		aisPortCallNumbers: [],

		stopsFeaturesAccepted: [],
		stopsFeaturesPlanned: [],
		stopsFeaturesAuthorized: [],
		stopsFeaturesConfirmed: [],
		stopsFeaturesInitiated: [],

		searchingtext: '',

		/**
		 * Panels
		 */
		activePanel: SETTINGS_PANEL_ID,
		activePanelOptions: null,
		dynamicPanels: [],
		dynamicPanelsId: [],

		/**
		 * Map Settings
		 */

		map: {
			legendExpanded: false,
			zoom: null,
			bbox: INITIAL_BOUNDS_KPIS
		},

		layers: {
			[AIS_VESSEL_GROUP_ID]: {
				identifier: AIS_VESSEL_GROUP_ID,
				opened: false,
				active: true,
				group: true,
				filtered: false,
				[FILTER_AIS_VISIT_LAYER_ID]: true,
				[FILTER_SERVICE_LAYER_ID]: true,
				[FILTER_OTHER_VESSEL_LAYER_ID]: true
			},
			[STOP_PREVISION_GROUP_ID]: {
				identifier: STOP_PREVISION_GROUP_ID,
				opened: false,
				active: false,
				group: true,
				filtered: false,
				[STOP_ACCEPTED_LAYER_ID]: false,
				[STOP_PLANNED_LAYER_ID]: false,
				[STOP_AUTHORIZED_LAYER_ID]: false,
				[STOP_CONFIRMED_LAYER_ID]: false,
				[STOP_INITIATED_LAYER_ID]: false
			},
			[CEP_ZONES_ID]: {
				identifier: CEP_ZONES_ID,
				opened: false,
				active: false,
				group: false,
				filtered: false
			}
		}
	},

	// Map panel situacion de buques
	mappanelperiods: {
		map: null,
		mapPeriodsIsLoaded: false,
		mapStyleVisualization: STATUS_VIS,
		mapPortVisualization: 0,
		mapFitPortExtent: null,

		// Stops sin procesar
		stopsFeaturesInstantInitially: [],

		// Past and Future
		stopsFeaturesInstant: [],

		// Planning
		stopsFeaturesInstantPL: [],
		stopsFeaturesInstantModifiedPL: [],
		stopsFeaturesInstantWithModifiedPL: [],
		stopsFeaturesInstantRemovedPL: [],

		// Authorization
		stopsFeaturesInstantInitiallyAU: [],
		stopsFeaturesInstantAU: [],
		stopsFeaturesInstantModifiedAU: [],
		stopsFeaturesInstantWithModifiedAU: [],
		stopsFeaturesInstantRemovedAU: [],

		// Processiong vessels
		processVesselsRunning: false,

		// Search text panels
		searchingtextpastpanel: '',
		searchingtextfuturepanel: '',
		searchingtextrequestedspanel: '',
		searchingtextplanningpanel: '',

		// Dates panel initially
		fecIniPastpanel: FEC_PAST_INI,
		fecEndPastpanel: FEC_PAST_END,
		fecIniFuturepanel: FEC_TODAY,
		fecEndFuturepanel: FEC_FUTURE_END,
		fecIniRequestedspanel: FEC_TODAY,
		fecEndRequestedspanel: FEC_REQPL_INI,
		fecIniPlanningpanel: FEC_TODAY,
		fecEndPlanningpanel: FEC_REQPL_INI,

		/**
		 * Instant panels data
		 */
		pastStatusCode: ['FI', 'IN'],
		futureStatusCode: ['IN', 'PL', 'AU', 'CO', 'AC', 'RQ'],
		requestedsStatusCode: ['PL', 'RQ', 'AC'],
		planningStatusCode: ['PL', 'RQ', 'AC'],
		requestedsViewStatusMapCode: ['IN', 'AU', 'PA'], // Iniciados y autorizados tambien visibles en el mapa
		planningViewStatusMapCode: ['IN', 'AU', 'PA'], // Iniciados y autorizados tambien visibles en el mapa

		/**
		 * Panels
		 */
		activePanel: INSTANT_PAST_PANEL_ID,
		activePanelOptions: null,
		panelParentActive: INSTANT_PAST_PANEL_ID,
		dynamicPanels: [],
		dynamicPanelsId: [],

		/**
		 * Map Settings
		 */

		map: {
			legendExpanded: false,
			zoom: null,
			bbox: INITIAL_BOUNDS_KPIS
		}
	},

	mapvisits: {
		map: {
			zoom: null
		}
	},

	settingsPanel: {
		isOpened: {
			filters: false,
			layers: false
		}
	}
};
