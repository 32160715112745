import FilterService from '@/services/filter.service.js';

export function getStatusStops() {
	let statusid = [];
	const stats = JSON.parse(window.localStorage.getItem('workingStopStatus'));
	if (stats) {
		stats.forEach((element) => {
			statusid.push(element.stopstatuscode);
		});
	}

	return statusid;
}

export default {
	data() {
		return {
			visitModelName: 'visit',
			subscriptionModelName: 'subscription',
			visits: [],
			subscriptions: [],
			visitsLoaded: true,
			currentDate: this.getCurrentDate(),
			searchText: '',
			zoomIn: 0,
			currentStatus: this.getStatus(),
			orderingBy: 'eta'
		};
	},
	computed: {
		onPuiModelsLoadedAndSaved() {
			return this.$store.state.modelsLoadedAndSaved;
		},
		filterCurrentDateMin() {
			if (this.zoomIn === 0) {
				return `${this.currentDate}T00:00:00Z`;
			}
			const previousDate = new Date(this.currentDate);
			previousDate.setDate(new Date(this.currentDate).getDate() - this.zoomIn);
			const dd = (previousDate.getDate() < 10 ? '0' : '') + previousDate.getDate();
			const MM = (previousDate.getMonth() + 1 < 10 ? '0' : '') + (previousDate.getMonth() + 1);
			const yyyy = previousDate.getFullYear();
			return `${yyyy}-${MM}-${dd}T00:00:00Z`;
		},
		filterCurrentDateMax() {
			if (this.zoomIn === 0) {
				return `${this.currentDate}T23:59:59Z`;
			}
			const nextDate = new Date(this.currentDate);
			nextDate.setDate(new Date(this.currentDate).getDate() + this.zoomIn);
			const dd = (nextDate.getDate() < 10 ? '0' : '') + nextDate.getDate();
			const MM = (nextDate.getMonth() + 1 < 10 ? '0' : '') + (nextDate.getMonth() + 1);
			const yyyy = nextDate.getFullYear();
			return `${yyyy}-${MM}-${dd}T23:59:59Z`;
		},
		currentDateMin() {
			const previousDate = new Date(this.currentDate);
			previousDate.setDate(new Date(this.currentDate).getDate() - this.zoomIn);
			const dd = (previousDate.getDate() < 10 ? '0' : '') + previousDate.getDate();
			const MM = (previousDate.getMonth() + 1 < 10 ? '0' : '') + (previousDate.getMonth() + 1);
			const yyyy = previousDate.getFullYear();
			return `${dd}/${MM}/${yyyy} 00:00`;
		},
		currentDateMax() {
			const nextDate = new Date(this.currentDate);
			nextDate.setDate(new Date(this.currentDate).getDate() + this.zoomIn);
			const dd = (nextDate.getDate() < 10 ? '0' : '') + nextDate.getDate();
			const MM = (nextDate.getMonth() + 1 < 10 ? '0' : '') + (nextDate.getMonth() + 1);
			const yyyy = nextDate.getFullYear();
			return `${dd}/${MM}/${yyyy} 23:59`;
		}
	},
	watch: {
		onPuiModelsLoadedAndSaved() {
			this.onReady();
		}
	},
	mounted() {
		if (this.$store.getters.getModelByName(this.visitModelName)) {
			this.onReady();
		}

		this.$puiEvents.$on('operations-toolbar_dateSelected', (newDateSelected) => {
			if (this.currentDate !== newDateSelected) {
				this.currentDate = newDateSelected;
				this.getSubscriptions();
				this.getSlotsShipCount();
				this.getVisits();
			}
		});
		this.$puiEvents.$on('operations-toolbar_zoomIn', (zoomIn) => {
			if (this.zoomIn !== zoomIn) {
				this.zoomIn = zoomIn;
				this.getSubscriptions();
				this.getSlotsShipCount();
				this.getVisits();
			}
		});
		this.$puiEvents.$on('operations-toolbar_statusSelected', (status) => {
			console.log(status);
			const stats = JSON.parse(window.localStorage.getItem('workingStopStatus'));
			console.log(stats);

			if (!status) {
				this.currentStatus = status;
			} else {
				const currentStatusTemp = [];
				if (status.includes('ESTIMATED')) {
					if (stats.some((objeto) => objeto.stopstatuscode === 'RQ')) {
						currentStatusTemp.push('RQ');
					}
					if (stats.some((objeto) => objeto.stopstatuscode === 'AC')) {
						currentStatusTemp.push('AC');
					}
					if (stats.some((objeto) => objeto.stopstatuscode === 'AU')) {
						currentStatusTemp.push('AU');
					}
				}
				if (status.includes('CONFIRMED')) {
					if (stats.some((objeto) => objeto.stopstatuscode === 'CO')) {
						currentStatusTemp.push('CO');
					}
				}
				if (status.includes('INITIATED')) {
					currentStatusTemp.push('IN');
					if (stats.some((objeto) => objeto.stopstatuscode === 'IN')) {
						currentStatusTemp.push('IN');
					}
				}
				if (status.includes('FINALIZED')) {
					if (stats.some((objeto) => objeto.stopstatuscode === 'FI')) {
						currentStatusTemp.push('FI');
					}
					if (stats.some((objeto) => objeto.stopstatuscode === 'IV')) {
						currentStatusTemp.push('IV');
					}
					if (stats.some((objeto) => objeto.stopstatuscode === 'IG')) {
						currentStatusTemp.push('IG');
					}
					if (stats.some((objeto) => objeto.stopstatuscode === 'RE')) {
						currentStatusTemp.push('RE');
					}
					if (stats.some((objeto) => objeto.stopstatuscode === 'AN')) {
						currentStatusTemp.push('AN');
					}
				}
				this.currentStatus = currentStatusTemp;
			}
		});
		this.$puiEvents.$on('operations-toolbar_searchingText', (searching) => {
			this.searchText = searching;
			if (this.searchVisits) {
				this.searchVisits();
			}
		});
		this.$puiEvents.$on('operations-toolbar_orderingBy', (ordering) => {
			this.orderingBy = ordering;
			this.getSubscriptions();
			this.getSlotsShipCount();
			this.getVisits();
		});
		this.$puiEvents.$on('operationscountdown_reload', () => {
			this.getSubscriptions();
			this.getSlotsShipCount();
			this.getVisits();
		});
	},
	destroyed() {
		this.$puiEvents.$off('operations-toolbar_dateSelected');
		this.$puiEvents.$off('operations-toolbar_searchingText');
		this.$puiEvents.$off('operations-toolbar_orderingBy');
		this.$puiEvents.$off('operationscountdown_reload');
	},
	methods: {
		onReady() {
			this.getEtcSafetyMargin();
			this.getEventsEnabled();
			this.getSubscriptions();
			this.getSlotsShipCount();
			this.getVisits();
		},
		getEtcSafetyMargin() {
			const puiVariableUrl = '/puivariable/getVariable/ETC_SAFETY_MARGIN';
			this.$puiRequests.getRequest(
				puiVariableUrl,
				null,
				(response) => {
					if (response.data) {
						this.$store.state.operations.etcSafetyMargin = response.data;
					}
				},
				(error) => {
					this.$store.dispatch('puiRequestShowServerError', { error: error, vue: this });
				}
			);
		},
		getEventsEnabled() {
			const puiVariableUrl = '/puivariable/getVariable/EVENTS_ENABLED';
			this.$puiRequests.getRequest(
				puiVariableUrl,
				null,
				(response) => {
					if (response.data) {
						const eventsEnabled = response.data;
						this.visitEvents = this.visitEvents.filter((event) => eventsEnabled.includes(event));
						this.stopEvents = this.stopEvents.filter((event) => eventsEnabled.includes(event));
					}
				},
				(error) => {
					this.$store.dispatch('puiRequestShowServerError', { error: error, vue: this });
				}
			);
		},
		getSubscriptions() {
			if (this.$store.getters.hasFunctionality('CREATE_SUBSCRIPTION')) {
				const subscriptionModel = this.$store.getters.getModelByName(this.subscriptionModelName);
				if (!subscriptionModel) {
					return;
				}

				const params = this.getSubscriptionParams();
				this.$puiRequests.postRequest(
					subscriptionModel.url.list,
					params,
					(response) => {
						if (response.data && response.data.data && response.data.data.length > 0) {
							for (let i = 0, length = response.data.data.length; i < length; i++) {
								const subscription = response.data.data[i];
								this.subscriptions.push(subscription.visit);
							}
						}
					},
					(error) => {
						this.$store.dispatch('puiRequestShowServerError', { error: error, vue: this });
					}
				);
			}
		},
		getSlotsShipCount() {
			const visitModel = this.$store.getters.getModelByName(this.visitModelName);
			if (!visitModel) {
				return;
			}

			const params = {
				date: `${this.currentDate}T00:00:00Z`,
				portId: window.localStorage.getItem('workingPortsId')
			};
			this.$puiRequests.getRequest(
				visitModel.url.getSlotShipCount,
				params,
				async (response) => {
					try {
						await this.fillSlotsFromResponse(response);
					} catch (error) {
						console.error(error);
					}
				},
				(error) => {
					console.error(error);
				}
			);
		},
		getVisits() {
			this.visitsLoaded = false;
			const visitModel = this.$store.getters.getModelByName(this.visitModelName);
			if (!visitModel) {
				return;
			}

			const params = this.getParams();
			this.$puiRequests.postRequest(
				visitModel.url.list,
				params,
				async (response) => {
					try {
						await this.fillVisitsFromResponse(response);
					} catch (error) {
						console.error(error);
					}
				},
				(error) => {
					this.$store.dispatch('puiRequestShowServerError', { error: error, vue: this });
					this.visitsLoaded = true;
				}
			);
		},
		getSubscriptionParams() {
			const filter = {
				groupOp: 'and',
				groups: [],
				rules: [{ field: 'usr', op: 'eq', data: this.$store.getters.getUser }]
			};
			const params = {
				model: this.subscriptionModelName,
				page: 1,
				queryLang: 'es',
				rows: 200,
				filter: filter
			};
			return params;
		},
		getParams() {
			const filter = {
				groupOp: 'and',
				groups: [
					{
						groupOp: 'or',
						groups: this.getFilterGroups(),
						rules: []
					}
				],
				rules: [{ field: 'portid', op: 'in', data: window.localStorage.getItem('workingPortsId') }]
			};
			const params = {
				model: 'visit',
				page: 1,
				queryLang: 'es',
				rows: 200,
				filter: filter
			};
			if (this.orderingBy) {
				params.order = [{ column: this.orderingBy, direction: 'asc' }];
			}

			return params;
		},
		getFilterGroups() {
			// FIXME: Only for commercial use, remove in future!!!
			if (this.$store.getters.getUser === 'huelva-operaciones') {
				return FilterService.getHuelvaFilterGroups(this.filterCurrentDateMin, this.filterCurrentDateMax);
			} else if (this.$store.getters.getUser === 'operations-malta') {
				return FilterService.getMaltaFilterGroups(this.filterCurrentDateMin, this.filterCurrentDateMax);
			}
			return FilterService.getDefaultFilterGroups(this.filterCurrentDateMin, this.filterCurrentDateMax);
		},
		getStatus() {
			let statusid = [];
			const stats = JSON.parse(window.localStorage.getItem('workingVisitStatus'));
			stats.forEach((element) => {
				statusid.push(element.visitstatuscode);
			});
			return statusid;
		}
	}
};
