import Vue from 'vue';
import VueI18n from 'vue-i18n';
import * as deepmerge from 'deepmerge';

import enPui from '@Pui9Translations/translations/en.json';
import esPui from '@Pui9Translations/translations/es.json';

import enApp from './en.json';
import esApp from './es.json';

const en = deepmerge.all([enPui, enApp]);
const es = deepmerge.all([esPui, esApp]);

Vue.use(VueI18n);

export default new VueI18n({
	locale: 'es',
	fallbackLocale: 'es',
	silentFallbackWarn: true,
	messages: { en: en, es: es }
});
