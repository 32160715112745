import { STOP_PREVISION_GROUP_ID, AIS_VESSEL_GROUP_ID } from '@/components/operations/map/constants/layers';

export default {
	isCMAShippingLine: (state) => {
		//return state.session.properties && state.session.properties.IS_NAVIERA && state.session.properties.NAVIERA_ID === 2182;
		return true;
	},
	isPortAuthority: (state) => {
		return state.session.properties && state.session.properties.IS_AUTPOR;
	},
	isOperator: (state) => {
		return state.session.properties && state.session.properties.IS_OPERATOR;
	},
	isTerminal: (state) => {
		return state.session.properties && state.session.properties.IS_TERMINAL;
	},
	getCompanyId: (state) => {
		return state.session.properties && state.session.properties.COMPANY_ID;
	},
	getCompanyPorts: (state) => {
		return state.session.properties && state.session.properties.COMPANY_PORTS;
	},
	isTerminalAPMT: (state) => {
		return state.session.properties && state.session.properties.IS_TERMINAL && state.session.properties.TERMINAL_ID === 279;
	},
	isTerminalTTIA: (state) => {
		return state.session.properties && state.session.properties.IS_TERMINAL && state.session.properties.TERMINAL_ID === 127;
	},
	isTerminalAPMTLC: (state) => {
		return state.session.properties && state.session.properties.IS_TERMINAL && state.session.properties.TERMINAL_ID === 282;
	},
	isTerminalHPLCT: (state) => {
		return state.session.properties && state.session.properties.IS_TERMINAL && state.session.properties.TERMINAL_ID === 283;
	},
	isBerthPlannerPro: (state) => {
		return (
			state.session.properties &&
			(!state.session.properties.IS_TERMINAL || (state.session.properties.IS_TERMINAL && state.session.properties.BERTH_PLANNER_PRO))
		);
	},
	getDefaultQuayByTerminal: (state) => {
		if (state.session.properties && state.session.properties.IS_TERMINAL) {
			if (state.session.properties.TERMINAL_ID === 279) {
				return state.berthplanner.defaultQuayAPMT; // APMT Algeciras
			} else if (state.session.properties.TERMINAL_ID === 127) {
				return state.berthplanner.defaultQuayTTIA; // Algeciras TTIA
			} else if (state.session.properties.TERMINAL_ID === 282) {
				return state.berthplanner.defaultQuayAPMTLC; // APMT Lázaro Cardenas
			} else if (state.session.properties.TERMINAL_ID === 283) {
				return state.berthplanner.defaultQuayHPLCT; // Hutchinson Ports LCT
			} else if (state.session.properties.TERMINAL_ID === 290) {
				return state.berthplanner.defaultQuayOPLP; // OPCSA LAS PALMAS
			} else if (state.session.properties.TERMINAL_ID === 292 || state.session.properties.TERMINAL_ID === 293) {
				const portIdGES = window.localStorage.getItem('workingPortsId');
				if (portIdGES == 4) {
					return state.berthplanner.defaultQuayGESLP; // LAS PALMAS GESPORT
				} else if (portIdGES == 6) {
					return state.berthplanner.defaultQuayGESAR; // ARRECIFE GESPORT
				}
			} else if (state.session.properties.TERMINAL_ID === 296) {
				return state.berthplanner.defaultQuayMAFV; // FUERTEVENTURA MAJORERA
			} else if (
				state.session.properties.TERMINAL_ID === 291 ||
				state.session.properties.TERMINAL_ID === 294 ||
				state.session.properties.TERMINAL_ID === 295
			) {
				const portIdBOL = window.localStorage.getItem('workingPortsId');
				if (portIdBOL == 6) {
					return state.berthplanner.defaultQuayMOAR; // ARRECIFE BOLUDA
				} else if (portIdBOL == 5) {
					return state.berthplanner.defaultQuayROBO; // ROSARIO BOLUDA
				} else if (portIdBOL == 4) {
					return state.berthplanner.defaultQuayBOLP; // LP BOLUDA
				}
			}
		} else {
			const algecirasPort = state.session.properties.PORTS.find((port) => {
				return port.id === 1;
			});
			if (algecirasPort && algecirasPort.id == window.localStorage.getItem('workingPortsId')) {
				return state.berthplanner.defaultQuayTTIA; // Algeciras TTIA
			}
		}
		return undefined;
	},
	getUserBerths: (state) => {
		return state.session.properties && state.session.properties.BERTHS;
	},
	getUserBerthIds: (state) => {
		let userQuays = [];
		let userQuaysId = [];
		userQuays = state.session.properties && state.session.properties.BERTHS;
		userQuaysId = userQuays ? userQuays.map((quay) => quay.id) : null;
		return userQuaysId;
	},
	getRawPortBerths: (state) => {
		const ports = window.localStorage.getItem('workingPortsId').split(',').map(Number);
		let allQuays = JSON.parse(JSON.stringify(state.session.properties && state.session.properties.BERTHS));
		let quays = allQuays.filter((quay) => ports.includes(quay.portid));
		console.log();
		quays.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));
		return quays;
	},
	getUserPorts: (state) => {
		return state.session.properties && state.session.properties.PORTS;
	},
	getUserPortById: (state) => {
		return (id) => {
			if (state.session.properties && state.session.properties.PORTS) {
				const port = state.session.properties.PORTS.find((port) => {
					return port.id === id;
				});
				return port ? port.portname : port;
			}
			return undefined;
		};
	},

	// Map Config
	getUserQuaysId(state) {
		return state.mapconfig.userQuaysId;
	},
	stopsStatusValues: (state) => {
		return state.mapconfig.stopsStatusValues;
	},
	stopsTypeValues: (state) => {
		return state.mapconfig.stopsTypeValues;
	},
	stopsOperationsValues: (state) => {
		return state.mapconfig.stopsOperationsValues;
	},
	defaultBackgroundColor: (state) => {
		return state.mapconfig.defaultBackgroundColor;
	},
	defaultStrokeColor: (state) => {
		return state.mapconfig.defaultStrokeColor;
	},
	getAllConfigPortCompany: (state) => {
		return state.operations.configPortCompany;
	},
	getTargetIdleTimeBy: (state) => {
		return (portId, locationId, idleTimeProperty) => {
			if (state.operations.configPortCompany) {
				for (const config of state.operations.configPortCompany) {
					if (config.portid != portId) {
						continue;
					}
					if (!config.locations) {
						continue;
					}
					if (!config.locations.includes(',')) {
						return config.locations == locationId ? config[idleTimeProperty] : undefined;
					}
					const locations = config.locations.split(',');
					for (let i = 0; i < locations.length; i++) {
						if (locations[i] == locationId) {
							return config[idleTimeProperty];
						}
					}
				}
			}
			return undefined;
		};
	},
	getDashboardIdBy: (state) => {
		return (portId) => {
			if (state.operations.configPortCompany) {
				for (const config of state.operations.configPortCompany) {
					if (config.portid == portId) {
						if (config.isautpor && state.session.properties.IS_AUTPOR) {
							return config.dashboardid;
						}
						if (config.isterminal && state.session.properties.IS_TERMINAL && config.companyid == state.session.properties.TERMINAL_ID) {
							return config.dashboardid;
						}
					}
				}
			}
			return undefined;
		};
	},
	editStopBlock: (state) => {
		return state.session.functionalities.includes('INSERT_BERTHBLOCK') || state.session.functionalities.includes('UPDATE_BERTHBLOCK');
	},
	quayItems(state) {
		let allQuays = JSON.parse(JSON.stringify(state.session.properties && state.session.properties.BERTHS));
		let quays = [];

		quays = quays.concat(allQuays.filter((quay) => window.localStorage.getItem('workingPortsId').includes(quay.portid)));

		quays.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));
		return quays;
	},

	// Operations AIS
	// DYNAMIC PANELS
	getAisPortCallNumbers: (state) => {
		return state.mappanelais.aisPortCallNumbers;
	},
	getMapAisFitPortExtent: (state) => {
		return state.mappanelais.mapFitPortExtent;
	},
	getMapAisStyleVisualization: (state) => {
		return state.mappanelais.mapStyleVisualization;
	},

	getMapAisPortVisualization: (state) => {
		return state.mappanelais.mapPortVisualization;
	},

	getMapAisIsLoaded: (state) => {
		return state.mappanelais.mapAisIsLoaded;
	},

	activePanelAIS: (state) => {
		return state.mappanelais.activePanel;
	},

	activePanelOptionsAIS: (state) => {
		return state.mappanelais.activePanelOptions;
	},

	dynamicPanelsAIS: (state) => {
		return state.mappanelais.dynamicPanels;
	},

	zoomAIS: (state) => {
		return state.mappanelais.map.zoom;
	},

	layers: (state) => {
		return state.mappanelais.layers;
	},

	getStopsFeaturesAccepted: (state) => {
		return state.mappanelais.stopsFeaturesAccepted;
	},

	getStopsFeaturesPlanned: (state) => {
		return state.mappanelais.stopsFeaturesPlanned;
	},

	getStopsFeaturesAuthorized: (state) => {
		return state.mappanelais.stopsFeaturesAuthorized;
	},

	getStopsFeaturesConfirmed: (state) => {
		return state.mappanelais.stopsFeaturesConfirmed;
	},

	getStopsFeaturesInitiated: (state) => {
		return state.mappanelais.stopsFeaturesInitiated;
	},
	getPortAuthorityId: (state) => {
		return window.localStorage.getItem('workingPortAuthorityId');
	},
	getPortlocode: (state) => {
		return state.operations.portlocode;
	},
	getPortsIds: (state) => {
		let portsIds = [];
		let ports = state.session.properties && state.session.properties.PORTS;
		if (ports instanceof Array && ports.length > 0) {
			ports.forEach((port) => portsIds.push(port.id));
		}
		return portsIds;
	},
	getShipSelectedId: (state) => {
		return state.operations.shipSelectedId;
	},

	// Map panel Periods
	getProcessVesselsRunning: (state) => {
		return state.mappanelperiods.processVesselsRunning;
	},
	getMapPeriodsFitPortExtent: (state) => {
		return state.mappanelperiods.mapFitPortExtent;
	},
	getMapPeriodsStyleVisualization: (state) => {
		return state.mappanelperiods.mapStyleVisualization;
	},
	getMapPeriodsPortVisualization: (state) => {
		return state.mappanelperiods.mapPortVisualization;
	},
	getMapPeriodsIsLoaded: (state) => {
		return state.mappanelperiods.mapPeriodsIsLoaded;
	},
	getPastStatusCode: (state) => {
		return state.mappanelperiods.pastStatusCode;
	},
	getFutureStatusCode: (state) => {
		return state.mappanelperiods.futureStatusCode;
	},
	getRequestedsStatusCode: (state) => {
		return state.mappanelperiods.requestedsStatusCode;
	},
	getRequestedsViewStatusMapCode: (state) => {
		return state.mappanelperiods.requestedsViewStatusMapCode;
	},
	getPlanningStatusCode: (state) => {
		return state.mappanelperiods.planningStatusCode;
	},
	getPlanningViewStatusMapCode: (state) => {
		return state.mappanelperiods.planningViewStatusMapCode;
	},

	// Stops iniciales
	getStopsFeaturesInstantInitially: (state) => {
		return state.mappanelperiods.stopsFeaturesInstantInitially;
	},

	// Past and Future
	getStopsFeaturesInstant: (state) => {
		return state.mappanelperiods.stopsFeaturesInstant;
	},

	// Planning
	getStopsFeaturesInstantPL: (state) => {
		return state.mappanelperiods.stopsFeaturesInstantPL;
	},

	getStopsFeaturesInstantModifiedPL: (state) => {
		return state.mappanelperiods.stopsFeaturesInstantModifiedPL;
	},

	getStopsFeaturesInstantWithModifiedPL: (state) => {
		return state.mappanelperiods.stopsFeaturesInstantWithModifiedPL;
	},

	getFeaturesInstantRemovedPL: (state) => {
		return state.mappanelperiods.stopsFeaturesInstantRemovedPL;
	},

	// Authorization
	getStopsFeaturesInstantAU: (state) => {
		return state.mappanelperiods.stopsFeaturesInstantAU;
	},

	getStopsFeaturesInstantModifiedAU: (state) => {
		return state.mappanelperiods.stopsFeaturesInstantModifiedAU;
	},

	getStopsFeaturesInstantWithModifiedAU: (state) => {
		return state.mappanelperiods.stopsFeaturesInstantWithModifiedAU;
	},

	getFeaturesInstantRemovedAU: (state) => {
		return state.mappanelperiods.stopsFeaturesInstantRemovedAU;
	},

	activePanelPeriods: (state) => {
		return state.mappanelperiods.activePanel;
	},

	activePanelOptionsPeriods: (state) => {
		return state.mappanelperiods.activePanelOptions;
	},

	panelParentActive: (state) => {
		return state.mappanelperiods.panelParentActive;
	},

	dynamicPanelsPeriods: (state) => {
		return state.mappanelperiods.dynamicPanels;
	},

	zoomPeriods: (state) => {
		return state.mappanelperiods.map.zoom;
	},

	// Layers
	previsionGroupLayers: (state) => {
		return state.mappanelais.layers[STOP_PREVISION_GROUP_ID];
	},

	aisGroupLayers: (state) => {
		return state.mappanelais.layers[AIS_VESSEL_GROUP_ID];
	},

	// Map visits
	zoomVisits: (state) => {
		return state.mapvisits.map.zoom;
	},

	// Map config
	berths: (state) => {
		return state.mapconfig.berths;
	},

	bollards: (state) => {
		return state.mapconfig.bollards;
	},

	loading: (state) => {
		return state.mapconfig.loading;
	},

	mapPortsExtent: (state) => {
		return state.mapconfig.mapPortsExtents;
	},

	mapAutorityExtent: (state) => {
		return state.mapconfig.mapAutorityExtent;
	},

	getActiveVisitStatusid: (state) => {
		return ['RV', 'CN', 'AC', 'AU', 'IN', 'RQ', 'PL', 'PA'];
	},

	getInactiveVisitStatusid: (state) => {
		return ['AN', 'DE', 'RE', 'FI', 'IV', 'IC'];
	},

	getActiveStopStatusid: (state) => {
		return ['WA', 'CO', 'IN', 'AC', 'AU', 'SO', 'RQ', 'PL'];
	},

	getActiveMovementStatusid: (state) => {
		return [1, 2, 3, 4];
	}
};
